/* eslint-disable @typescript-eslint/no-var-requires */
const i18nConfig = require('./config/i18n')

const i18nextHttpBackend = require('i18next-http-backend')

const isBrowser = typeof window !== 'undefined'

const timeStampNow = new Date().getTime()

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: i18nConfig.defaultLocale,
    locales: i18nConfig.locales,
    localeDetection: false,
  },
  ns: ['cms'], // this is mandatory for crowdin OTA to work
  // en-US does not work with crowdin OTA so en-GB should be considered
  fallbackLng: {
    default: ['en-GB'],
    en: ['en-GB'],
    de: ['de-DE'],
    'en-TX': ['en-GB'],
    'de-TX': ['de-DE'],
    'fr-TX': ['fr-BE'],
  },
  nonExplicitSupportedLngs: true,
  defaultNS: i18nConfig.defaultNS,
  initImmediate: false,
  trailingSlash: true,
  serializeConfig: false,
  backend: {
    loadPath: `https://distributions.crowdin.net/${process.env.CROWDIN_DISTRIBUTION_HASH}/content/main/{{lng}}/{{ns}}.json?timestamp=${timeStampNow}`,
    reloadInterval: 1000 * 30, // TODO to be discussed which numbers fit best for CMS
  },
  use: isBrowser ? [] : [i18nextHttpBackend], // translations should not be fetched client side
}
