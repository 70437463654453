import env from '@beam-australia/react-env'
import { datadogRum } from '@datadog/browser-rum'
import { initializeDatadogBrowserLogs } from '@knauf-group/ct-shared-nextjs/datadog'

const service = 'cms-frontend'

datadogRum.init({
  applicationId: `${env('DATADOG_APPLICATION_ID')}`,
  clientToken: `${env('DATADOG_CLIENT_TOKEN')}`,
  site: 'datadoghq.eu',
  service: 'cms-frontend',
  env: `${env('DATADOG_RUM_ENV')}`,
  version: `${env('APP_CMS_VERSION')}`,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  silentMultipleInit: true,
})

datadogRum.startSessionReplayRecording()

initializeDatadogBrowserLogs({
  service,
  version: `${env('APP_CMS_VERSION')}`,
})
