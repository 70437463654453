import env from '@beam-australia/react-env'

/**
 * Contentful will resolve linked entries up to 10 levels deep by default.
 * This is a performance issue, so typically we try limit the number of levels within 5 - 7 range.
 * Higher values = heavier responses so we should avoid it whenever possible.
 *
 * https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/links/retrieval-of-linked-items
 */

export const { BASE_URL, CONTENTFUL_PREVIEW_TRIGGER_KEY } = process.env
export const EXIT_PREVIEW_URL = env('EXIT_PREVIEW_URL')

export const PRODUCT_IMAGE_BACKGROUND_COLOR = '255,255,255'

export const YOUTUBE_NOCOOKIE_HOST_URL = 'https://www.youtube-nocookie.com'
export const YOUTUBE_URL_REGEX = /youtu(be\.com|\.be).*(\?v=|\/embed\/|\/)(.{11})/
export const VIMEO_HOST_URL = 'https://vimeo.com'
export const VIMEO_URL_REGEX = /vimeo\.com.*(.{9})/
export const FALLBACK_IMAGES = {
  BACKGROUND: '/images/fallback-images/fallback_image_background.svg',
  CONTACTS: '/images/fallback-images/fallback_image_contacts.svg',
  DEFAULT_1_1_RATIO: '/images/fallback-images/fallback_image_1_1.svg',
  DEFAULT_4_3_RATIO: '/images/fallback-images/fallback_image_4_3.svg',
  DEFAULT_16_9_RATIO: '/images/fallback-images/fallback_image_16_9.svg',
  DOCUMENTS: '/images/fallback-images/fallback_image_documents.svg',
  ICON: '/images/fallback-images/fallback_image_icon.svg',
  VIDEO_THUMBNAIL: '/images/fallback-images/fallback_image_video_thumbnail.png',
}

export const STAGE_BRAND_IMAGES = {
  SMALL: '/images/stage-brand/stage_brand_bg_small.webp',
  LTR: '/images/stage-brand/stage_brand_bg.webp',
  RTL: '/images/stage-brand/stage_brand_bg_RTL.webp',
}

// For when to use each size option, please see https://knaufjira.atlassian.net/wiki/spaces/HC/pages/4130046056/How+FE+handle+Images#Smart-Crop-Size-Definition-Table
export enum SMART_CROP_TOKENS {
  CF_4X3_L = 'CF_4x3_L',
  CF_4X3_S = 'CF_4x3_S',
  CF_16X9_L = 'CF_16x9_L',
  CF_16X9_S = 'CF_16x9_S',
  CF_BACKGROUND_L = 'CF_background_L',
  CF_BACKGROUND_S = 'CF_background_S',
  CF_HALF_BACKGROUND = 'CF_half_background',
  CF_HERO_TEASER_BG_M = 'CF_heroteaserbg_M',
  CF_HERO_TEASER_BG_XS = 'CF_heroteaserbg_XS',
}
export const FEATURE_TAG_KEY = 'FT'

export const TAG_PREFIXES = {
  [FEATURE_TAG_KEY]: 'FT_',
}

export const FEATURE_SHOW_COOKIE_LIST_TABLE = 'showCookieListTable'

export const ADOBE_LAUNCH_SCRIPT_URL = env('ADOBE_LAUNCH_SCRIPT_URL')
