import type { NextRouter } from 'next/router'

import type { ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import { isValidUrl } from '@knauf-group/ct-designs/utils/utils'

export const getPageSlugFromRouterPath = (router: NextRouter): string => {
  if (!router) {
    return ''
  }

  const pathName = router.asPath.split('/').slice(-1).join()
  const pathNameWithoutQuery = pathName.split('?').shift()
  const isHomePage = pathNameWithoutQuery === router.locale || pathNameWithoutQuery === ''

  return isHomePage ? 'homepage' : pathNameWithoutQuery
}

export const isContactLink = (reference: ReferenceWeb) =>
  reference?.href?.startsWith('mailto:') || reference?.href?.startsWith('tel:')
export const isDownloadLink = (reference: ReferenceWeb) => {
  if (!isValidUrl(reference?.href)) {
    return false
  }

  const pathName = new URL(reference.href).pathname

  return pathName.startsWith('/api/download-center/')
}
